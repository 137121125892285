
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'

const textDataUrl = page_json.page_jsons.Leadership;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Leadership() {
    useEffect(() => {
        AOS.init(); // Initialize AOS
    }, []);

    return (
        <div data-aos="fade-up">
            <div className="container-fluid m-0 p-0 brd-crmb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home </a></li>
                                    <li className="breadcrumb-item"><a href="/About">About Us </a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Leadership</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid leader-inner">
                <div className="container">
                    <h2>{textData.layer1.heading}</h2>
                    <div className="row">
                        {
                            textData.editableList_leadership.map(function (item) {
                                return (<div className="col-lg-4 col-md-4 col-sm-4 col-">
                                    <img src={item.img} className="img-fluid" alt="leader-img" />
                                    <p>{item.name}</p>
                                    <i>{item.post}</i>
                                </div>)
                            })
                        }
                    </div>
                    {/* <div className="row">
                        <br/><br/><br/>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-">
                            <img src={deb} className="img-fluid" alt="leader-img" />
                            <p>Deb Misra</p>
                            <i>CEO</i>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-">
                            <img src={ajay} className="img-fluid" alt="leader-img" />
                            <p>Ajay Kaushik </p>
                            <i>Vice President</i>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-">
                            <img src={sateesh} className="img-fluid" alt="leader-img" />
                            <p>Sateesh Narayan</p>
                            <i>Senior Director</i>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-">
                            <img src={alex} className="img-fluid" alt="leader-img" />
                            <p>Alex Resnikoff</p>
                            <i>Director of Business Development</i>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-">
                            <img src={thomas} className="img-fluid" alt="leader-img" />
                            <p>Tom Romeo</p>
                            <i>Strategic Advisor (Healthcare)</i>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1 col-"></div>
                        <div className="col-lg-1 col-md-1 col-sm-1 col-"></div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-">
                            <img src={dibyant} className="img-fluid" alt="leader-img" />
                            <p>Dibyant Upadhyay</p>
                            <i>Director of Technology</i>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-">
                            <img src={raajeev} className="img-fluid" alt="leader-img" />
                            <p>Rajeev Rajan</p>
                            <i>Director of Client Engagement</i>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-">
                            <img src={tanay} className="img-fluid" alt="leader-img" />
                            <p>Tanya Pandey </p>
                            <i>Talent Acquisition Lead | HRBP</i>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-">
                            <img src={hamza} className="img-fluid" alt="leader-img" />
                            <p>Muhamad Hamza Ooma</p>
                            <i>Practice Lead (Liferay)</i>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-">
                            <img src={kousalya} className="img-fluid" alt="leader-img" />
                            <p>Kousalya Sankarramalingam</p>
                            <i>Practice Lead (Business Analyst)</i>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-"></div>
                    </div> */}
                </div>
            </div>






            <div className="container-fluid trust">
                <div className="container trusted-img">
                    <div className="row">
                        <h2>{textData.layer2.heading}</h2>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-">
                            <img src={textData.layer2.img} alt="logos" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-">
                            <div className="heading">
                                <h2><span>{textData.layer3.heading.text1}</span> {textData.layer3.heading.text2} <span>{textData.layer3.heading.text3}</span></h2>
                            </div>
                            <div className="more-2">
                                <a href={textData.layer3.button.url}>{textData.layer3.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-">
                            <div className="gif">
                                <img src={textData.layer3.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                            <h2>{textData.layer4.heading}</h2>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-">
                                <img src={textData.layer4.img} alt="logos" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Leadership;