import React, { useEffect, useRef, useState } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

const Recaptcha = ({ setIsCaptchaValid }) => {

  const inputRef = useRef(null);
  useEffect(() => {
    loadCaptchaEnginge(6, 'white', 'green');
  }, []);
  
  useEffect(() => {
    document.getElementById('reload_href').addEventListener('click', reloadHandler);
  }, []);

  const reloadHandler = () => {
    setIsCaptchaValid(false)
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const checkCaptcha = (value) => {
    if (validateCaptcha(value, false)) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
    }
  };

  return (
    <form style={{
      margin: '25px 0'
    }}>
      <LoadCanvasTemplate />
      <input
        style={{
          marginTop: '7px'
        }}
        ref={inputRef}
        type="text"
        placeholder="Enter CAPTCHA code"
        maxLength={6}
        onChange={(e) => checkCaptcha(e.target.value)}
      />
    </form>
  );
};

export default React.memo(Recaptcha);
