
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'

const textDataUrl = page_json.page_jsons.FinancialServices;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function FinancialServices() {
    // useEffect(() => {
    //     AOS.init(); // Initialize AOS
    // }, []);

    return (
        <div data-aos="fade-up">
            <div className="container-fluid m-0 p-0 brd-crmb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home </a></li>
                                    <li className="breadcrumb-item"><a href="/Industries/FinancialServices">Industries </a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Financial Services</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-0">
                <div className="inner-banner">
                    <img src={textData.layer1.img} className="d-block w-100" alt="banner-img" />
                    <div className="inner-banner-text">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                    <h2>{textData.layer1.heading.text1} <span>{textData.layer1.heading.text2}<br /> {textData.layer1.heading.text3}</span>{textData.layer1.heading.text4}<br />{textData.layer1.heading.text5}</h2>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-">
                        <div className="ai-img">
                            <img src={textData.layer2.img} className="img-fluid" alt="industry" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-">
                        <div className="ai-text-3">
                            <p><b>{textData.layer2.body1}</b></p>
                            <h2>{textData.layer2.heading}</h2>
                            <p>{textData.layer2.body2}</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 col-">
                        <div className="ai-text-2">
                            <p><b>{textData.layer3.body1}</b></p>
                            <h2>{textData.layer3.heading}</h2>
                            <p>{textData.layer3.body2}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-">
                        <div className="ai-img">
                            <img src={textData.layer3.img} className="img-fluid" alt="industry" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid finance-success">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-">
                            <div className="healthcare-inner">
                                <h2><span>{textData.layer4.heading.text1}</span> {textData.layer4.heading.text2}</h2>
                                <p>{textData.layer4.body}</p>
                                <a href={textData.layer4.contact.url}>{textData.layer4.contact.text}<i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-">
                            <div className="stories">
                                <a href={textData.layer4.data.link1.url}>
                                    <p>{textData.layer4.data.link1.text}</p>
                                    <i className="fa-solid fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-">
                            <div className="stories">
                                <a href={textData.layer4.data.link2.url}>
                                    <p>{textData.layer4.data.link2.text}</p>
                                    <i className="fa-solid fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-">
                            <div className="stories">
                                <a href={textData.layer4.data.link3.url}>
                                    <p>{textData.layer4.data.link3.text}</p>
                                    <i className="fa-solid fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container-fluid Resilient">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-">
                            <div id="carouselExample" className="carousel slide">
                                <div className="carousel-inner">
                                    {
                                        textData.editableList_carousel.map((item, index) => {
                                            if (index === 0) {
                                                return (
                                                    <div className={"carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active")}>
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-12 col-">
                                                                <div className="integrations">
                                                                    <img src={item.img} className="img-fluid" alt="industry" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-7 col-sm-12 col-">
                                                                <div className="integrations-text">
                                                                    <h2>{item.heading}</h2>
                                                                    <p>{item.body}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                            } else {
                                                return (
                                                    <div className={"carousel-item carousel-item-".concat(String.toString(index + 1))}>
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-12 col-">
                                                                <div className="integrations">
                                                                    <img src={item.img} className="img-fluid" alt="industry" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-7 col-sm-12 col-">
                                                                <div className="integrations-text">
                                                                    <h2>{item.heading}</h2>
                                                                    <p>{item.body}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })

                                    }




                                    {/* <div className="carousel-item carousel-item-2">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-">
                                                <div className="integrations">
                                                    <img src={textData.layer6.img} className="img-fluid" alt="industry" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-12 col-">
                                                <div className="integrations-text">
                                                    <h2>{textData.layer6.heading}</h2>
                                                    <p>{textData.layer6.body}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="control-btn-13">
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                        <i className="fa-solid fa-arrow-left"></i>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid trust">
                <div className="container trusted-img">
                    <div className="row">
                        <h2>{textData.layer7.heading}</h2>
                        <div className="col-lg-12 col-md-12 col-sm-12 ol-xs-">
                            <img src={textData.layer7.img} alt="logos" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-">
                            <div className="heading">
                                <h2><span>{textData.layer8.heading.text1}</span>{textData.layer8.heading.text2}<span>{textData.layer8.heading.text3}</span></h2>
                            </div>
                            <div className="more-2">
                                <a href={textData.layer8.button.url}>{textData.layer8.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-">
                            <div className="gif">
                                <img src={textData.layer8.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                            <h2>{textData.layer9.heading}</h2>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-">
                                <img src={textData.layer9.img} alt="logos" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinancialServices;