
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'

const textDataUrl = page_json.page_jsons.Manufacturing;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();


function Manufacturing () {
    useEffect(() => {
        AOS.init(); // Initialize AOS
    }, []);
    const [activePanel, setActivePanel] = useState(null);
    const togglePanel = (panelId) => {
        setActivePanel(activePanel === panelId ? null : panelId);
    };
    var left_index = 0;
    return (
        <div data-aos="fade-up">
            <div className="container-fluid m-0 p-0 brd-crmb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home </a></li>
                                    <li className="breadcrumb-item"><a href="/Industries/FinancialServices">Industries </a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Manufacturing</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-0">
                <div className="inner-banner">
                    <img src={textData.layer1.img} className="d-block w-100" alt="banner-img" />
                    <div className="inner-banner-text">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                    <h2 className="animate__animated animate__fadeInLeft">{textData.layer1.heading.text1} <span>{textData.layer1.heading.text2}</span> {textData.layer1.heading.text3} <span>{textData.layer1.heading.text4}</span></h2>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid manufacture">
                <div className="container">
                    <h2>{textData.layer2.heading.text1}<br /> {textData.layer2.heading.text2}<br /> {textData.layer2.heading.text3}</h2>
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 col-">
                            <div className="manufacture-text">
                                <p><span>{textData.layer2.data.body1.serial_no}</span>{textData.layer2.data.body1.text} </p>
                                <p><span>{textData.layer2.data.body2.serial_no}</span>{textData.layer2.data.body2.text} </p>
                                <p><span>{textData.layer2.data.body3.serial_no}</span>{textData.layer2.data.body3.text} </p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12 col-">
                            <div className="manufacture-img">
                                <img src={textData.layer2.img} className="img-fluid" alt="industry" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid globally">
                <div className="container">
                    <h2><span>{textData.layer3.heading.text1} </span> {textData.layer3.heading.text2}<br /> {textData.layer3.heading.text3}</h2>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-">
                            <div id="carouselExampleCaptions" className="carousel slide">

                                <div className="carousel-inner">
                                {
                                    textData.layer3.details.map((item, index) => {
                                        if ( index === 0 ) {
                                            return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                            <div className="card">
                                                                <h5><span> {item.heading}</span></h5>
                                                                <p>{item.body}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                            } else {
                                                return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                            <div className="card">
                                                                <h5><span> {item.heading}</span></h5>
                                                                <p>{item.body}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        })
                                        
                                }
                                    {/* <div className="carousel-item carousel-item-1 active">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                <div className="card">
                                                    <h5><span> {textData.layer3.details.data1.heading}</span></h5>
                                                    <p>{textData.layer3.details.data1.body}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item carousel-item-2">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                <div className="card">
                                                    <h5><span> {textData.layer3.details.data2.heading}</span></h5>
                                                    <p>{textData.layer3.details.data2.body}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item carousel-item-2">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                <div className="card">
                                                    <h5><span> {textData.layer3.details.data3.heading}</span></h5>
                                                    <p>{textData.layer3.details.data3.body}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="control-btn">
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                        <span className="visually-hidden">{textData.layer3.button_next}</span>
                                    </button>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                        <i className="fa-solid fa-arrow-left"></i>
                                        <span className="visually-hidden">{textData.layer3.button_previous}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-"></div>
                    </div>
                </div>
            </div>

            <div className="container-fluid Resilient">
                <div className="container">
                    <h2>{textData.layer4.heading.text1}<br /> {textData.layer4.heading.text2}</h2>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-">
                            <img src={textData.layer4.img} className="img-fluid" alt="Resilient_solutions" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-">
                            <div id="carouselExample" className="carousel slide">
                                <div className="carousel-inner">
                                {
                                    textData.layer4.editable_details.map((item, index) => {
                                        if (index === 0 ) {
                                            return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                            <div className="card-1">
                                                                <h5>{item.heading}</h5>
                                                                <p>{item.body.text1}</p>&nbsp;
                                                                <p>{item.body.text2}</p>&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                            } else {
                                                return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                            <div className="card-1">
                                                                <h5>{item.heading}</h5>
                                                                <p>{item.body.text1}</p>&nbsp;
                                                                <p>{item.body.text2}</p>&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        })
                                        
                                }
                                    
                                    {/* <div className="carousel-item carousel-item-1 active">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                <div className="card-1">
                                                    <h5>{item.heading}</h5>
                                                    <p>{item.body.text1}</p>&nbsp;
                                                    <p>{item.body.text2}</p>&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item carousel-item-2">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                <div className="card-1">
                                                    <h5>{textData.layer4.details.data2.heading}</h5>
                                                    <p>{textData.layer4.details.data2.body.text1}</p>&nbsp;
                                                    <p>{textData.layer4.details.data2.body.text2}</p>&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}


                                </div>
                                <div className="control-btn-1">
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                        <span className="visually-hidden">{textData.layer4.button_next}</span>
                                    </button>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                        <i className="fa-solid fa-arrow-left"></i>
                                        <span className="visually-hidden">{textData.layer4.button_previous}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-"></div>
                    </div>
                    <div className="self">
                        <h2>{textData.layer5.heading.text1}<br /> {textData.layer5.heading.text2}</h2>
                        <p><b>{textData.layer5.body1}</b></p>&nbsp;
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-">
                                <div className="module-border-wrap">
                                    <div className="module">
                                        <p id="pdng">{textData.layer5.data.self_services1}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-">
                                <div className="module-border-wrap">
                                    <div className="module">
                                        <p id="pdng">{textData.layer5.data.self_services2}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-">
                                <div className="module-border-wrap">
                                    <div className="module">
                                        <p id="pdng">{textData.layer5.data.self_services3}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-">
                                <div className="module-border-wrap">
                                    <div className="module">
                                        <p id="pdng">{textData.layer5.data.self_services4}</p>
                                    </div>
                                </div>
                            </div>
                        </div>&nbsp;

                        <p>{textData.layer5.body2}</p>
                        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                            <h2>{textData.layer6.heading}</h2>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-">
                                {
                                    textData.layer6.collapsibles_left.map((item, index) => {
                                        left_index = index
                                        return(
                                            <div className="panel panel-default">
                                                <div className="panel-heading" role="tab" id={ "heading" + index }>
                                                    <h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
                                                        aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index }> {item.heading} </a></h4>
                                                </div>
                                                <div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
                                                    data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
                                                    <div className="panel-body">
                                                        <p>{item.body}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-">
                                {
                                    textData.layer6.collapsibles_right.map((item, index) => {
                                        index = index + left_index + 1
                                        return(
                                            <div className="panel panel-default">
                                                <div className="panel-heading" role="tab" id={ "heading" + index }>
                                                    <h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
                                                        aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index }> {item.heading} </a></h4>
                                                </div>
                                                <div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
                                                    data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
                                                    <div className="panel-body">
                                                        <p>{item.body}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                                    {/* <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingOne">
                                            <h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseOne' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseOne')}
                                                aria-expanded={activePanel === 'collapseOne' ? 'true' : 'false'} aria-controls="collapseOne"	> {textData.layer6.collapsibles.collapsible1.heading} </a></h4>
                                        </div>
                                        <div className={`collapse ${activePanel === 'collapseOne' ? 'show' : ''}`} id="collapseOne" aria-labelledby="headingOne"
                                            data-parent="#accordion"> <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne"></div>
                                            <div className="panel-body">
                                                <p>{textData.layer6.collapsibles.collapsible1.body}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingTwo">
                                            <h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseTwo' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseTwo')}
                                                aria-expanded={activePanel === 'collapseTwo' ? 'true' : 'false'} aria-controls="collapseTwo"	> {textData.layer6.collapsibles.collapsible2.heading} </a></h4>
                                        </div>
                                        <div className={`collapse ${activePanel === 'collapseTwo' ? 'show' : ''}`} id="collapseTwo" aria-labelledby="headingTwo"
                                            data-parent="#accordion"> <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo"></div>
                                            <div className="panel-body">
                                                <p>{textData.layer6.collapsibles.collapsible2.body}</p>         </div>

                                        </div>
                                    </div>
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingThree">
                                            <h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseThree' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseThree')}
                                                aria-expanded={activePanel === 'collapseThree' ? 'true' : 'false'} aria-controls="cocollapseThreellapse1"	> {textData.layer6.collapsibles.collapsible3.heading} </a></h4>
                                        </div>
                                        <div className={`collapse ${activePanel === 'collapseThree' ? 'show' : ''}`} id="collapseThree" aria-labelledby="headingThree"
                                            data-parent="#accordion"> <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree"></div>
                                            <div className="panel-body">
                                                <p>{textData.layer6.collapsibles.collapsible3.body}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-">
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingFour">
                                            <h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseFour' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseFour')}
                                                aria-expanded={activePanel === 'collapseFour' ? 'true' : 'false'} aria-controls="collapseFour"	> {textData.layer6.collapsibles.collapsible4.heading} </a></h4>
                                        </div>
                                        <div className={`collapse ${activePanel === 'collapseFour' ? 'show' : ''}`} id="collapseFour" aria-labelledby="headingFour"
                                            data-parent="#accordion"> <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour"></div>
                                            <div className="panel-body">
                                                <p>{textData.layer6.collapsibles.collapsible4.body}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingFive">
                                            <h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseFive' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseFive')}
                                                aria-expanded={activePanel === 'collapseFive' ? 'true' : 'false'} aria-controls="collapseFive"	> {textData.layer6.collapsibles.collapsible5.heading} </a></h4>
                                        </div>
                                        <div className={`collapse ${activePanel === 'collapseFive' ? 'show' : ''}`} id="collapseFive" aria-labelledby="headingFive"
                                            data-parent="#accordion"> <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive"></div>
                                            <div className="panel-body">
                                                <p>{textData.layer6.collapsibles.collapsible5.body}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingSix">
                                            <h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseSix' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseSix')}
                                                aria-expanded={activePanel === 'collapseSix' ? 'true' : 'false'} aria-controls="collapseSix" > {textData.layer6.collapsibles.collapsible6.heading} </a></h4>
                                        </div>
                                        <div className={`collapse ${activePanel === 'collapseSix' ? 'show' : ''}`} id="collapseSix" aria-labelledby="headingSix"
                                            data-parent="#accordion"> <div id="collapseSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix"></div>
                                            <div className="panel-body">
                                                <p>{textData.layer6.collapsibles.collapsible6.body}</p>
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid monitoring">
                <div className="container">
                    <h2>{textData.layer7.heading1.text1}<br /> {textData.layer7.heading1.text2}</h2>
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-7 col-">
                            <p>{textData.layer7.body.text1}</p>&nbsp;
                            <p>{textData.layer7.body.text2}</p>&nbsp;
                            <p>{textData.layer7.body.text3}</p>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-5 col-"></div>
                    </div>
                </div>
            </div>

            <div className="container-fluid Resilient">
                <div className="container">
                    <div className="success-heading">
                        <h2>{textData.layer8.heading}</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-">
                            <div id="carouselExample" className="carousel slide">
                                <div className="carousel-inner">
                                    <div className="carousel-item carousel-item-1 active">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-">
                                                <div className="integrations">
                                                    <img src={textData.layer8.img} className="img-fluid" alt="industry" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-12 col-">
                                                <div className="integrations-text">
                                                    <h5>{textData.layer8.data.story1.heading}</h5>&nbsp;
                                                    <p>{textData.layer8.data.story1.body} </p><br />
                                                    <a href={textData.layer8.data.story1.button.url}>{textData.layer8.data.story1.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid trust">
                <div className="container trusted-img">
                    <div className="row">
                        <h2>{textData.layer9.heading}</h2>
                        <div className="col-lg-12 col-md-12 col-sm-12 ol-xs-">
                            <img src={textData.layer9.img} alt="logos" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-">
                            <div className="heading">
                                <h2><span>{textData.layer10.heading.text1}</span>{textData.layer10.heading.text2}<span>{textData.layer10.heading.text3}</span></h2>
                            </div>
                            <div className="more-2">
                                <a href={textData.layer10.button.url}>{textData.layer10.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-">
                            <div className="gif">
                                <img src={textData.layer10.button.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                            <h2>{textData.layer11.heading}</h2>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-">
                                <img src={textData.layer11.img} alt="logos" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Manufacturing;

