import './content/public/css/custom.css';
import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import 'animate.css/animate.min.css';
import page_json from './config/config.json'

import { Helmet } from 'react-helmet';


const em_logo = page_json.background_img.em_logo;

function Header() {
    useEffect(() => {
        // Prevent the page from being loaded in an iframe
        if (window.self !== window.top) {
            window.top.location = window.self.location;
        }
    }, []);


function closeMenuOnScroll() {
        const menuToggle = document.getElementById('menuToggle');
        if (menuToggle && menuToggle.checked) {
            menuToggle.checked = false; // Uncheck the checkbox to close the menu
        }
    }

    // Event listener to close the menu when scrolling the body
    useEffect(() => {
        window.addEventListener('scroll', closeMenuOnScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', closeMenuOnScroll);
        };
    }, []); // Empty dependency array ensures the effect runs only once


    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">
            <Helmet>
                <meta http-equiv="Content-Security-Policy" content="frame-ancestors 'none'" />
                <link rel = 'dns-prefetch' type='text/css'  href = '//t.visitorqueue.com'  /> 
                <link rel='stylesheet' type='text/css' href="https://p.visitorqueue.com/styles/c178230c-ba2d-418c-a90e-46e0ba47cf07.css" id = 'vq_flick_styles' />
                <script id="vq_tracking" src="//t.visitorqueue.com/p/tracking.min.js?id=c178230c-ba2d-418c-a90e-46e0ba47cf07" data-id='c178230c-ba2d-418c-a90e-46e0ba47cf07' async="1"></script>
                <script id="vq_personalisation" src="//personalisation.visitorqueue.com/p/personalisation.min.js?id=c178230c-ba2d-418c-a90e-46e0ba47cf07" data-id='c178230c-ba2d-418c-a90e-46e0ba47cf07' async="1"></script>
            </Helmet>
                <a className="navbar-brand" href="/"><img src={em_logo} className="img-fluid" alt="logo" /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" id="navbarToggleBtn">
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-1"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <div className="desktop">
                    <ul className="desktop-ul">
                        <li className="nav-item dropdown"><a className="nav-link" href="/Industries/FinancialServices" id="navbarDropdown" role="button" aria-expanded="false">Industries</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/Industries/FinancialServices">Financial Services</a></li>
                                <li><a className="dropdown-item" href="/Industries/Healthcare">Healthcare</a></li>
                                <li><a className="dropdown-item" href="/Industries/Manufacturing">Manufacturing</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown"><a className="nav-link" href="/Services/DigitalTransformation" id="navbarDropdown" role="button" aria-expanded="false">Services</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/Services/DigitalTransformation">Digital Transformation</a></li>
                                <li><a className="dropdown-item" href="/Services/AI&Data">AI & Data</a></li>
                                {/* <li><a className="dropdown-item" href="#">Tech Services</a></li> */}
                                <li><a className="dropdown-item" href="/Services/TechnologyTalent">Tech Talent</a></li>
                                <li><a className="dropdown-item" href="/Services/ManagedITServices">Managed IT Services </a></li>
                                <li><a className="dropdown-item" href="/Services/SecurityCertificationsServices">Managed Security Services & Certifications</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown"><a className="nav-link" href="/Technologies/Liferay" id="navbarDropdown" role="button" aria-expanded="false">Technologies</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/Technologies/Liferay">Liferay</a></li>
                                <li><a className="dropdown-item" href="/Technologies/Salesforce">Salesforce</a></li>
                                <li><a className="dropdown-item" href="/Technologies/FullStackDevelopment">Full Stack</a></li>
                                <li><a className="dropdown-item" href="/Technologies/DevOps">DevOps</a></li>
                            </ul>
                        </li>
                    </ul>
                        </div>
                        <div className="mobile">

                        <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="/Industries/FinancialServices" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Industries</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/Industries/FinancialServices">Financial Services</a></li>
                                <li><a className="dropdown-item" href="/Industries/Healthcare">Healthcare</a></li>
                                <li><a className="dropdown-item" href="/Industries/Manufacturing">Manufacturing</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="/Services/DigitalTransformation" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Services</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/Services/DigitalTransformation">Digital Transformation</a></li>
                                <li><a className="dropdown-item" href="/Services/AI&Data">AI & Data</a></li>
                                {/* <li><a className="dropdown-item" href="#">Tech Services</a></li> */}
                                <li><a className="dropdown-item" href="/Services/TechnologyTalent">Tech Talent</a></li>
                                <li><a className="dropdown-item" href="/Services/ManagedITServices">Managed IT Services </a></li>
                                <li><a className="dropdown-item" href="/Services/SecurityCertificationsServices">Managed Security Services & Certifications</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="/Technologies/Liferay" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Technologies</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/Technologies/Liferay">Liferay</a></li>
                                <li><a className="dropdown-item" href="/Technologies/Salesforce">Salesforce</a></li>
                                <li><a className="dropdown-item" href="/Technologies/FullStackDevelopment">Full Stack</a></li>
                                <li><a className="dropdown-item" href="/Technologies/DevOps">DevOps</a></li>
                            </ul>
                        </li>
                        </div>

                        <li className="nav-item dropdown third-nav"><a className="nav-link dropdown-toggle" href="/About/EMLeadership" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a><a href="/About" className="primary-menu">About Us</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/About/EMLeadership">Leadership</a></li>
                                <li><a className="dropdown-item" href="/About/Team">Team</a></li>
                                <li><a className="dropdown-item" href="/About/Alliances">Alliances</a></li>
                                <li><a className="dropdown-item" href="/About/Careers">Careers</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown third-nav"><a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a><a href="/Resources" className="primary-menu">Resources</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/Resources/SuccessStories">Success Stories</a></li>
                                <li><a className="dropdown-item" href="/Resources/Blogs">Blogs</a></li>
                                <li><a className="dropdown-item" href="/Resources/News">News</a></li>
                                <li><a className="dropdown-item" href="/Resources/Testimonials">Testimonials</a></li>
                            </ul>
                        </li>
                        <li className="nav-item sec-nav"><a className="nav-link" href="/Contact">Contact</a></li>
                        <input type="checkbox" id="menuToggle" className="fragment" />
                        <label htmlFor="menuToggle">
                            <span className="bar top"></span>
                            <span className="bar middle"></span>
                            <span className="bar bottom"></span>
                        </label>
                        <aside>
                            <div className="aside-section aside-right">
                                <ul className="aside-list navigation__list">
                                    <div className="dropdown">
                                        <li><a className="btn dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-chevron-down"></i></a><a href="/About" className="primary-menu">About Us</a>
                                            <ul className="dropdown-menu" id="dm-2">
                                                <li><a className="dropdown-item" href="/About/EMLeadership">Leadership</a></li>
                                                <li><a className="dropdown-item" href="/About/Team">Team</a></li>
                                                <li><a className="dropdown-item" href="/About/Alliances">Alliances</a></li>
                                                <li><a className="dropdown-item" href="/About/Careers">Careers</a></li>
                                            </ul>
                                        </li>
                                        <li><a className="btn dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-chevron-down"></i></a><a href="/Resources" className="primary-menu">Resources</a>
                                            <ul className="dropdown-menu" id="dm-2">
                                                <li><a className="dropdown-item" href="/Resources/SuccessStories">Success Stories</a></li>
                                                <li><a className="dropdown-item" href="/Resources/Blogs">Blogs</a></li>
                                                <li><a className="dropdown-item" href="/Resources/News">News</a></li>
                                                <li><a className="dropdown-item" href="/Resources/Testimonials">Testimonials</a></li>
                                            </ul>
                                        </li>
                                        <li><a className="btn" href="/Contact" role="button" aria-expanded="false">Contact </a></li>
                                    </div>
                                </ul>
                            </div>
                        </aside>
                    </ul>
                </div>
            </div>
        </nav>

    )
}

export default Header;
