
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'
import AWS from 'aws-sdk';
import { SES } from '@aws-sdk/client-ses';
import Recaptcha from './ReCaptcha';

// const location_bg = './content/public/images/Location_Bg.webp';
// const nj = './content/public/images/New-Jersey.webp';
// const ny = './content/public/images/New_York.webp';
// const noida = './content/public/images/Noida.webp';
// const em_logo = './content/public/images/EM_Logo.webp';
// const bangalore = './content/public/images/Bengaluru.webp';
// const loc1 = 'https://engineersmind-websitecontent.s3.us-east-1.amazonaws.com/New-Jersey.webp';
// const loc2 = 'https://engineersmind-websitecontent.s3.us-east-1.amazonaws.com/New_York.webp';
// const loc3 = 'https://engineersmind-websitecontent.s3.us-east-1.amazonaws.com/Noida.webp';
// const loc4 = 'https://engineersmind-websitecontent.s3.us-east-1.amazonaws.com/Bengaluru.webp';

var textDataUrl = page_json.backend_apis.countries;
var textDataResponse = await fetch(textDataUrl);
const countryList = await textDataResponse.json();

textDataUrl = page_json.page_jsons.Contact;
textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();
function Contact() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	
	const  [formData, setFormData] =  useState({});
	const [isCaptchaValid,setIsCaptchaValid] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
  	const [message, setMessage] = useState('');

	useEffect(()=>{
		handleChange({})
	},[isCaptchaValid])
	
	
	const handleInputChange = (event) => {
		const value = event.target.value;
	
		if (/^\d*$/.test(value)) {
		  setMobileNumber(value);
	
		  // Regular expression to match exactly 10 digits
		  const regex = /^\d{10}$/;
		  if (value.length === 0 || regex.test(value)) {
			setMessage(value.length === 10 ? '' : '');
		  } else {
			setMessage('Mobile number must be exactly 10 digits.');
		  }
		} else {
		  setMessage('Only digits are allowed.');
		}
	  };

	const  handleChange = (event) => {
		var value = formData
		if (event?.target?.name === "Firstname"){
			value.firstname = event.target.value
		} else if (event?.target?.name === "Lastname"){
			value.lastname = event.target.value
		} else if (event?.target?.name === "Email"){
			value.email = event.target.value
		} else if (event?.target?.name === "Phone"){
			value.phone = event.target.value
		} else if (event?.target?.name === "City"){
			value.city = event.target.value
		} else if (event?.target?.name === "Country"){
			value.country = event.target.value
		} else if (event?.target?.name === "Comments"){
			value.comment = event.target.value
		} else if (event?.target?.name === "check1"){
			value.check1 = event.currentTarget.checked
		} else if (event?.target?.name === "check2"){
			value.check2 = event.currentTarget.checked
		}
		if (value.firstname !== "" &&  value.selected !== "" &&  value.lastname !== "" &&  value.email !== ""
			&&  value.phone !== "" &&  value.city !== "" &&  value.country !== "" && value.check1 === true && isCaptchaValid) {
			document.getElementById("clickButton").disabled = false;

		} else {
			document.getElementById("clickButton").disabled = true;
		}
		setFormData(value);
	};

	const btnclick = async (e) => {
		e.preventDefault();
		
		// Prepare the data to be sent in the API request
		const requestData = {
		  name: `${formData.firstname} ${formData.lastname}`,
		  department: selectedOption,
		  email: formData.email,
		  phone: formData.phone,
		  city: formData.city,
		  country: formData.country,
		  comment: formData.comment,
		};
	  let bodytext = `<div className="application-container"><h1></h1><div className="applicant-info"><p><strong>Name:</strong> ${requestData.name} </p><p><strong>Email:</strong> ${requestData.name} </p><p><strong>Phone:</strong> ${formData.phone} </p><p><strong>City:</strong> ${formData.city} </p><p><strong>Country:</strong> ${formData.country} </p></div><div className="comment"><h2>Comments</h2><p>${formData.comment}</p></div></div>`;
	  let emailData={
		//sender: 'no-reply@em.engineersmind.com',
		sender: page_json.contact_api.fromEmail,
		recipient: page_json.contact_api.toEmail,
		subject: `Enquiry for ${selectedOption}`,
		body_text: bodytext,
		body_html: `<html><body>${bodytext}</body></html>`,
		//attachments: [{filename: 'example.txt', content: btoa('This is the content of the test file.')} ], // Array of objects with { filename: 'example.txt', content: 'base64string' }
	  };
		// Define the API endpoint
		const emailAPI = page_json.backend_apis.email_api;
		//const apiUrl = emailAPI;
	  console.log(emailData);
		try {
		  // Make the API call using fetch
		  const response = await fetch(emailAPI, {
			method: 'POST', // Use the appropriate HTTP method
			headers: {
			  'Content-Type': 'application/json', // Set the content type to JSON
			},
			body: JSON.stringify(emailData), // Convert the data to JSON
		  });
	  
		  // Check if the response is successful
		  if (response.ok) {
			// Optionally, parse the JSON response
			const responseData = await response.json();
			console.log('Response:', responseData);
			
			alert('Query sent successfully');
			window.location.reload(); // Reload the page on success
		  } else {
			// Handle errors
			console.error('Error:', response.statusText);
			alert('Query failed, try again!');
		  }
		} catch (error) {
		  // Handle exceptions
		  console.error('Exception:', error);
		  alert('An error occurred, please try again!');
		}
	  };

	  const handleSelectChange = (event) => {
		setSelectedOption(event.target.value);
	  };
	/*const btnclick = (e) => {
		e.preventDefault();
		var body = "<!DOCTYPE html><html><head><title>Contact page query:</title></head><body><ul><li>name: " + formData.firstname + " " + formData.lastname + "</li><li>email: " + formData.email + "</li><li>Phone: " + formData.phone + "</li><li>City: " + formData.city + "</li><li>Country: " + formData.country + "</li><li>Comment: " + formData.comment + "</li></ul></body></html>";
		const ses = new SES({region: process.env.REACT_APP_AWS_REGION,
			credentials: new AWS.Credentials(process.env.REACT_APP_AWS_ACCESS_KEY_ID, process.env.REACT_APP_AWS_SECRET_ACCESS_KEY) 
		});
		const params = {
			Source: page_json.contact_api.fromEmail,
			Destination: {
			  ToAddresses: [page_json.contact_api.toEmail],
			},
			Message: {
			  Body: {
				Html: {
				  Charset: 'UTF-8',
				  Data: body
				},
			  },
			  Subject: {
				Charset: 'UTF-8',
				Data: "Contact page query from " + formData.firstname + " " + formData.lastname
			  },
			},
		  };
		ses.sendEmail(params)
		.then((response) => {
			alert("Query sent");
			window.location.reload();
		}).catch(function (error) {
			alert("Query failed, try again!");
		})
	};*/
	return (
		<div data-aos="fade-up">
			<div className="container-fluid m-0 p-0 brd-crmb" id="crumb-2">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item active" aria-current="page">Contact</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid contact">
				<div className="container">
					<h2>{textData.layer1.heading}</h2>
					<p><span>{textData.layer1.body.text1}</span></p>
					<p>{textData.layer1.body.text2}</p>
					<p>{textData.layer1.body.text3}</p>
					<p>{textData.layer1.body.text4}</p>&nbsp;
					<p><a href={`mailto:${textData.layer1.body.text5}`}>{textData.layer1.body.text5}</a></p>
					<p><a href={`tel:${textData.layer1.body.text6.replace(/[\s-]/g, '')}`}>{textData.layer1.body.text6}</a></p>&nbsp;
					&nbsp;
					{/* <form id="myForm" action=" " method="post"> */}
					<form onSubmit={btnclick}> 
						<h2>{textData.layer2.heading}</h2>
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-">
								<div className="success1">
									<select id="Information" name="Information" value={selectedOption} className="form-control" required onChange={handleSelectChange}>
										<option value="" disabled>Select</option>
										<option value="Sales">Sales</option>
										<option value="Consulting">Consulting</option>
										<option value="HR">HR</option>
									</select>
									<label htmlFor="Information" className="form-label">{textData.layer2.body.text1}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<input type="text" value={formData.firstname} onChange={handleChange} className="form-control" name="Firstname" id="FirstName" required />
									<label htmlFor="FirstName" className="form-label">{textData.layer2.body.text2}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<input type="text" value={formData.lastname} onChange={handleChange} className="form-control" name="Lastname" id="LastName" required />
									<label htmlFor="LastName" className="form-label">{textData.layer2.body.text3}</label>
								</div>

							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<input type="email" value={formData.email} onChange={handleChange} className="form-control" name="Email" id="Email" required />
									<label htmlFor="Email" className="form-label">{textData.layer2.body.text4}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<input type="number" maxLength="10" className="form-control" value={formData.phone} onChange={handleInputChange}  name="Phone" id="Phone" title="Only numbers" pattern="[0-9]{10}" required />
									<label htmlFor="Phone" className="form-label">{textData.layer2.body.text5}</label>
									{message && (
										<p style={{ color: message === 'Mobile number is valid.' ? 'green' : 'red' }}>
										{message}
										</p>
									)}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<input type="text" value={formData.city} onChange={handleChange}  className="form-control" name="City" id="City" required />
									<label htmlFor="city" className="form-label">{textData.layer2.body.text6}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<select id="Country" name="Country" className="form-control" onChange={handleChange} required>
										<option value="">{textData.layer2.body.text7}</option>
										{
											countryList.data.map(function(item){
												return(<option value={item.name}>{item.name}</option>)
											  })
										}
									</select>
									<label htmlFor="Country" className="form-label">{textData.layer2.body.text8}</label>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-">
								<div className="success1">
									<textarea className="form-control" value={formData.comment} onChange={handleChange} name="Comments" id="Comments" rows="3"></textarea>
									<label htmlFor="Comments" className="form-label">{textData.layer2.body.text9} </label>
								</div>
							</div>&nbsp;
							<div className="mb-12 form-check">
								<input type="checkbox"  value={formData.agree1} onClick={handleChange} name="check1" className="form-check-input" id="agree1" required />
								<label className="form-check-label" htmlFor="agree1">{textData.layer2.body.text10}</label>
							</div>
							<div className="mb-12 form-check">
								<input type="checkbox"  value={formData.agree2} onClick={handleChange} name="check2" className="form-check-input" id="agree2"/>
								<label className="form-check-label" htmlFor="agree2">{textData.layer2.body.text11}</label>
							</div>
							<div className="mb-12">
								<Recaptcha setIsCaptchaValid={setIsCaptchaValid}/>
							</div>
							<button type="submit" id="clickButton" className="btn" disabled={true} >{textData.layer2.body.text12}</button>
						</div>
					</form>

				</div>
			</div>
			<div className="container-fluid loc-img">
				<div className="container">
					<h2>{textData.layer3.heading}</h2>
					<div className="row">
						{
							textData.layer3.editableList_locations.map(function(item){
								return(<div className="col-lg-3 col-md-3 col-sm-12 col-">
											<div className="location">
												<img src={item.img} alt="gif" className="img-fluid" />
												<p>{item.name}</p>
											</div>
										</div>)
							  })
						}
						
						{/* <div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="location">
								<img src={loc1} alt="gif" className="img-fluid" />
								<p>New Jersey</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="location">
								<img src={loc2} alt="gif" className="img-fluid" />
								<p>New York</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="location">
								<img src={loc3} alt="gif" className="img-fluid" />
								<p>Noida</p>
							</div>

						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="location">
								<img src={loc4} alt="gif" className="img-fluid" />
								<p>Bengaluru</p>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	)


}

export default Contact;



