

import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'

// import integration from './content/public/images/Integrations.webp';
// import data_proc from './content/public/images/Data-Processing.webp';
// import ai_data from './content/public/images/AI-Data.webp';

const textDataUrl = page_json.page_jsons.Liferay;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Liferay() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item"><a href="/Technologies/Salesforce">Technologies </a></li>
									<li className="breadcrumb-item active" aria-current="page">Liferay</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid p-0">
				<div className="inner-banner">
					<img src={textData.layer1.img} className="d-block w-100" alt="banner-img" />
					<div className="inner-banner-text">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
									<h2 className="animate__animated animate__fadeInLeft">{textData.layer1.heading.text1}<br /><span>{textData.layer1.heading.text2}</span> </h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid manufacture">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 col-md-12 col-sm-12 col-">
							<div className="card-9">
								<h2>{textData.layer2.heading.text1}</h2>
								<h5>{textData.layer2.heading.text2}</h5>&nbsp;
								<p>{textData.layer2.body.text1}</p>&nbsp;
								<p>{textData.layer2.body.text2}</p>
							</div>
						</div>
						<div className="col-lg-7 col-md-12 col-sm-12 col-">&nbsp;
							<div className="manufacture-img">
								<img src={textData.layer2.img} className="img-fluid" alt="industry" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid key-features">
				<div className="container">
					<h2>{textData.layer3.heading.text1} <span>{textData.layer3.heading.text2}</span></h2>
					<div className="row">
						{
							textData.layer3.data.map((item, index) => {
								return(
									<div className="col-lg-3 col-md-3 col-sm-12 col-">
										<div className="module-border-wrap">
											<div className="module">
												<p id="pdng">{item}</p>
											</div>
										</div>
									</div>
								)
							})
						}

						{/* <div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module1}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module2}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module3}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module4}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module5}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module6}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module7}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module8}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module9}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module10}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module11}</p>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>

			<div className="container-fluid liferay-inner">
				<div className="container">
					<h2>{textData.layer4.heading.text1}<br /> {textData.layer4.heading.text2}</h2>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-">
							<p>{textData.layer4.body.text1}</p>&nbsp;
							<p>{textData.layer4.body.text1}</p>
						</div>
					</div>
				</div>
				<div className="container">
					<h2>{textData.layer5.heading.text1}<br />{textData.layer5.heading.text2}</h2>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-">
							<p>{textData.layer5.body}</p>&nbsp;
							<div id="carouselCaptionsInner" className="carousel slide">
								<div className="carousel-inner">
								{
									textData.layer5.editable_details.map((item, index) => {
										if ( index === 0 ) {
											return (
												<div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
													<div className="card-10">
														<div className="row">
															<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																<ul className="liferay-slide">
																	<li><h5>{item.left.serial_no}</h5></li>
																	<li>{item.left.text}</li>
																</ul>
															</div>
															<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																<ul className="liferay-slide">
																	<li><h5>{item.right.serial_no}</h5></li>
																	<li>{item.right.text}</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											)
										} else {
											return (
												<div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
													<div className="card-10">
														<div className="row">
															<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																<ul className="liferay-slide">
																	<li><h5>{item.left.serial_no}</h5></li>
																	<li>{item.left.text}</li>
																</ul>
															</div>
															<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																<ul className="liferay-slide">
																	<li><h5>{item.right.serial_no}</h5></li>
																	<li>{item.right.text}</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											)
										}
									})
								}



									{/* <div className="carousel-item carousel-item-1 active">
										<div className="card-10">
											<div className="row">
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<ul className="liferay-slide">
														<li><h5>{textData.layer5.liferay_slide.body1.serial_no}</h5></li>
														<li>{textData.layer5.liferay_slide.body1.text}</li>
													</ul>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<ul className="liferay-slide">
														<li><h5>{textData.layer5.liferay_slide.body2.serial_no}</h5></li>
														<li>{textData.layer5.liferay_slide.body2.text}</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div className="carousel-item carousel-item-2">
										<div className="card-10">
											<div className="row">
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<ul className="liferay-slide">
														<li><h5>{textData.layer5.liferay_slide.body3.serial_no}</h5></li>
														<li>{textData.layer5.liferay_slide.body3.text}</li>
													</ul>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<ul className="liferay-slide">
														<li><h5>{textData.layer5.liferay_slide.body4.serial_no}</h5></li>
														<li>{textData.layer5.liferay_slide.body4.text}</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div className="carousel-item carousel-item-2">
										<div className="card-10">
											<div className="row">
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<ul className="liferay-slide">
														<li><h5>{textData.layer5.liferay_slide.body5.serial_no}</h5></li>
														<li>{textData.layer5.liferay_slide.body5.text}</li>
													</ul>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<ul className="liferay-slide">
														<li><h5>{textData.layer5.liferay_slide.body6.serial_no}</h5></li>
														<li>{textData.layer5.liferay_slide.body6.text}</li>
													</ul>
												</div>
											</div>
										</div>
									</div> */}
								</div>
								<div className="control-btn-11">
									<button className="carousel-control-next" type="button" data-bs-target="#carouselCaptionsInner" data-bs-slide="next">
										<i className="fa-solid fa-arrow-right"></i>
										<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
										<span className="visually-hidden">{textData.layer5.button_next}</span>
									</button>
									<button className="carousel-control-prev" type="button" data-bs-target="#carouselCaptionsInner" data-bs-slide="prev">
										<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
										<i className="fa-solid fa-arrow-left"></i>
										<span className="visually-hidden">{textData.layer5.button_previous}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<h2>{textData.layer6.heading}</h2>
					<p>{textData.layer6.body}</p>&nbsp;
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12 col-">
							<ul className="liferay-slide">
								<img src={textData.layer6.details.data1.img} alt="logos" className="img-fluid" />
								<li>{textData.layer6.details.data1.text}</li>
							</ul>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-">
							<ul className="liferay-slide">
								<img src={textData.layer6.details.data2.img} alt="logos" className="img-fluid" />
								<li>{textData.layer6.details.data2.text}</li>
							</ul>
						</div>
						&nbsp;
						<div className="col-lg-6 col-md-6 col-sm-12 col-">
							<ul className="liferay-slide">
								<img src={textData.layer6.details.data3.img} alt="logos" className="img-fluid" />
								<li>{textData.layer6.details.data3.text}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<h2>{textData.layer7.heading}</h2>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-">
						<ul className="liferay-slide-1">
							<li><h5>{textData.layer7.details.data1.serial_no}</h5></li>
							<li>{textData.layer7.details.data1.text}</li>
						</ul>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-">
						<ul className="liferay-slide-1">
							<li><h5>{textData.layer7.details.data2.serial_no}</h5></li>
							<li>{textData.layer7.details.data2.text}</li>
						</ul>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-">
						<ul className="liferay-slide-1">
							<li><h5>{textData.layer7.details.data3.serial_no}</h5></li>
							<li>{textData.layer7.details.data3.text}</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="container-fluid trust">
				<div className="container trusted-img">
					<div className="row">
						<h2>{textData.layer8.heading}</h2>
						<div className="col-lg-12 col-md-12 col-sm-12 col-">
							<img src={textData.layer8.img} alt="logos" className="img-fluid" />
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-">
							<div className="heading">
								<h2><span>{textData.layer9.heading.text1}</span>{textData.layer9.heading.text2}<span>{textData.layer9.heading.text3}</span></h2>
							</div>
							<div className="more-2">
								<a href={textData.layer9.button.url}>{textData.layer9.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-">
							<div className="gif">
								<img src={textData.layer9.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h2>{textData.layer10.heading}</h2>
							<div className="col-lg-12 col-md-12 col-sm-12 col-">
								<img src={textData.layer10.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Liferay;