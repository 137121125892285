
import React, { useEffect } from 'react';
//import React, { useEffect, useState } from 'react';
import AOS from 'aos';
//import axios from '../api/axios';
//import axios from 'axios';
import page_json from './config/config.json'

// const banner1 = 'https://engineersmind-websitecontent.s3.amazonaws.com/EM_Home_Page_Banner-1.webp';
// const banner2 = 'https://engineersmind-websitecontent.s3.amazonaws.com/AWS-banner.webp';
// const banner3 = 'https://engineersmind-websitecontent.s3.amazonaws.com/Microsoft+Azure.webp';
// const layer1 = 'https://engineersmind-websitecontent.s3.amazonaws.com/1-Layer-4.webp';
// const layer2 = 'https://engineersmind-websitecontent.s3.amazonaws.com/2-Layer-4.webp';
// const layer3 = 'https://engineersmind-websitecontent.s3.amazonaws.com/3-Layer-4.webp';
// const e = 'https://engineersmind-websitecontent.s3.amazonaws.com/e.webp';
// const logo = 'https://engineersmind-websitecontent.s3.amazonaws.com/Logos.webp';
// const banner_logo = 'https://engineersmind-websitecontent.s3.amazonaws.com/Banner-Logos.webp';
// const trusted_logo = 'https://engineersmind-websitecontent.s3.amazonaws.com/Trusted_by_Logos.webp';
// const financial = 'https://engineersmind-websitecontent.s3.amazonaws.com/Financial.webp';
// const healthcare = 'https://engineersmind-websitecontent.s3.amazonaws.com/Healthcare.webp';
// const manufacture = 'https://engineersmind-websitecontent.s3.amazonaws.com/Manufacturing.webp';
// const clientlogos = 'https://engineersmind-websitecontent.s3.amazonaws.com/client_Logos.webp';
// const clienticons = 'https://engineersmind-websitecontent.s3.amazonaws.com/Clientele_Icons.webp';
// const gif = 'https://engineersmind-websitecontent.s3.amazonaws.com/Em_Animation-1.gif';

const textDataUrl = page_json.page_jsons.Body;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Body() {
    //const [data, setData] = useState([]);
    useEffect(() => {
        AOS.init();
        /*axios.get('/pages')
            .then(response => setData(response.data))
            .catch(error => console.log(error));*/
    }, []);
    // if(Array.isArray(data.data)){
    //     let Home = data.data.filter(element => element.page_name === "Home");
    //     console.log('home data' + JSON.stringify(Home[0].page_name));
    // }else{
    //     console.error("data is not in array format");
    // }
    //console.log('home data 1' + JSON.stringify(data));

    return (
        <div data-aos="fade-up">
            <div className="container-fluid p-0">
                <div id="carouselExampleAutoplaying" className="carousel slide">
                    <div className="carousel-inner">
                        {
                            textData.editable_carousel_banner.map((item, index) => {
                                if (index === 0 ) {
                                    return( 
                                        <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                    <img src={item.img} className="d-block w-100" alt="banner-img" />
                                                    <div className="banner-text">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                                    <h2 className="animate__animated animate__fadeInLeft"><span>{item.heading.text1}</span>{item.heading.text2}<br /> <span>{item.heading.text3}</span> {item.heading.text4}<br />{item.heading.text5}</h2>
                                                                    <a href={item.button.url} className="animate__animated animate__fadeInLeft">{item.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="banner-logos">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                                    <img src={item.banner.img} className="img-dluid" alt="banner-logo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    } else {
                                        return( 
                                        <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                    <img src={item.img} className="d-block w-100" alt="banner-img" />
                                                    <div className="banner-text">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                                    <h2 className="animate__animated animate__fadeInLeft"><span>{item.heading.text1}</span>{item.heading.text2}<br /> <span>{item.heading.text3}</span> {item.heading.text4}<br />{item.heading.text5}</h2>
                                                                    <a href={item.button.url} className="animate__animated animate__fadeInLeft">{item.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="banner-logos">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                                    <img src={item.banner.img} className="img-dluid" alt="banner-logo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            })
                                
                        }
                        {/* <div className="carousel-item carousel-item-1 active">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                    <img src={textData.editable_carousel_banner.img} className="d-block w-100" alt="banner-img" />
                                    <div className="banner-text">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <h2 className="animate__animated animate__fadeInLeft"><span>{textData.editable_carousel_banner.heading.text1}</span>{textData.editable_carousel_banner.heading.text2}<br /> <span>{textData.editable_carousel_banner.heading.text3}</span> {textData.editable_carousel_banner.heading.text4}<br />{textData.editable_carousel_banner.heading.text5}</h2>
                                                    <a href={textData.editable_carousel_banner.button.url} className="animate__animated animate__fadeInLeft">{textData.editable_carousel_banner.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-logos">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <img src={textData.editable_carousel_banner.banner.img} className="img-dluid" alt="banner-logo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="carousel-item carousel-item-2">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                    <img src={banner2} className="d-block w-100" alt="banner-img" />
                                    <div className="banner-text">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <h2 className="animate__animated animate__fadeInLeft"><span>Empower</span> your<br /> organization with<br /> Data and AI</h2>
                                                    <a href="/Services/AI&Data" className="animate__animated animate__fadeInLeft">Explore <i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-logos">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <img src={banner_logo} className="img-dluid" alt="banner-logo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item carousel-item-2">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                    <img src={banner3} className="d-block w-100" alt="banner-img" />
                                    <div className="banner-text">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <h2 className="animate__animated animate__fadeInLeft">EM <span>Tech Talent Solutions</span><br />
                                                        Your gateway to innovation,<br /> excellence & growth</h2>
                                                    <a href="/Services/TechnologyTalent" className="animate__animated animate__fadeInLeft">Explore <i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-logos">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <img src={banner_logo} className="img-dluid" alt="banner-logo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="control-btn-2">
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                            <i className="fa-solid fa-arrow-right"></i>
                            <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                            <i className="fa-solid fa-arrow-left"></i>
                            <span className="visually-hidden">Previous</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="container-fluid layer-2">
                <div className="container">
                    <h2>{textData.layer2.heading}</h2>
                    <div className="card-header">
                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#financial" role="tab">{textData.layer2.subheadings.subheading1}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#healthcare" role="tab">{textData.layer2.subheadings.subheading2}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#manufacturing" role="tab">{textData.layer2.subheadings.subheading3}</a>
                            </li>
                        </ul>
                    </div>&nbsp;
                    <div className="card-body">
                        <div className="tab-content text-center">
                            <div className="tab-pane active" id="financial" role="tabpanel">
                                <div className="indus-txt">
                                    <p>{textData.layer2.details.detail1.body.text1}</p>
                                    <p>{textData.layer2.details.detail1.body.text2}</p>
                                    <p>{textData.layer2.details.detail1.body.text3}</p>
                                    <a href={textData.layer2.details.detail1.button.link}>{textData.layer2.details.detail1.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="tab-pane" id="healthcare" role="tabpanel">
                                <div className="indus-txt">
                                    <p>{textData.layer2.details.detail2.body.text1}</p>
                                    <p>{textData.layer2.details.detail2.body.text2}</p>
                                    <a href={textData.layer2.details.detail2.button.link}>{textData.layer2.details.detail2.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="tab-pane" id="manufacturing" role="tabpanel">
                                <div className="indus-txt">
                                    <p>{textData.layer2.details.detail3.body.text1}</p>
                                    <p>{textData.layer2.details.detail3.body.text2}</p>
                                    <p>{textData.layer2.details.detail3.body.text3}</p>
                                    <a href={textData.layer2.details.detail3.button.link}>{textData.layer2.details.detail3.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fliud testimonial">
                <h2>{textData.layer3.heading.text1}<br />{textData.layer3.heading.text2}</h2>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-">
                            <img src={textData.layer3.img} alt="logos" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid testi-home">
                <div className="container">
                    <h2>{textData.layer4.heading}</h2>
                    <div className="row">
                        <div className="col-lg-12 col-md 12 col-sm-12 col-xs-">
                            <div id="carouselExampleCaptions" className="carousel slide">
                                <div className="carousel-inner">
                                    {
                                        textData.layer4.editable_carousel.map((item, index) => {
                                            if (index === 0 ) {
                                                return( 
                                                    <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                        <div className="row">
                                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-"></div>
                                                            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-">
                                                                <p><i className="fa-solid fa-quote-left"></i>{item.testimony}<i className="fa-solid fa-quote-right"></i></p>&nbsp;
                                                                <p><b>{item.name}</b><br /><i>{item.post}</i></p>
                                                                <div className="buttoncss">
                                                                    <div className="control-btn-3">
                                                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                                            <i className="fa-solid fa-arrow-right"></i>
                                                                            <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                                            <span className="visually-hidden">Next</span>
                                                                        </button>
                                                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                                            <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                                            <i className="fa-solid fa-arrow-left"></i>
                                                                            <span className="visually-hidden">Previous</span>
                                                                        </button>
                                                                    </div>
                                                                    <p className="morecss">
                                                                        <a href={item.button.link}>{item.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                } else {
                                                    return( 
                                                    <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                        <div className="row">
                                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-"></div>
                                                            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-">
                                                                <p><i className="fa-solid fa-quote-left"></i>{item.testimony}<i className="fa-solid fa-quote-right"></i></p>&nbsp;
                                                                <p><b>{item.name}</b><br /><i>{item.post}</i></p>
                                                                <div className="buttoncss">
                                                                    <div className="control-btn-3">
                                                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                                            <i className="fa-solid fa-arrow-right"></i>
                                                                            <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                                            <span className="visually-hidden">Next</span>
                                                                        </button>
                                                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                                            <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                                            <i className="fa-solid fa-arrow-left"></i>
                                                                            <span className="visually-hidden">Previous</span>
                                                                        </button>
                                                                    </div>
                                                                    <p className="morecss">
                                                                        <a href={item.button.link}>{item.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        })
                    
                                    }


                                    {/* <div className="carousel-item carousel-item-1 active">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-"></div>
                                            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-">
                                                <p><i className="fa-solid fa-quote-left"></i>The Engineersmind team consistently delivers top-notch solutions tailored to our needs. Their expertise, reliability, and customer-centric approach make them an invaluable asset for driving business growth and innovation. I wholeheartedly recommend Engineersmind's services to anyone in search of exceptional IT software development in the US financial services sector...<i className="fa-solid fa-quote-right"></i></p>&nbsp;
                                                <p><b>Chase Hyland</b><br /><i>COO (TRCM Fund)</i></p>
                                                <div className="buttoncss">
                                                    <div className="control-btn-3">
                                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                            <i className="fa-solid fa-arrow-right"></i>
                                                            <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Next</span>
                                                        </button>
                                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                            <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                            <i className="fa-solid fa-arrow-left"></i>
                                                            <span className="visually-hidden">Previous</span>
                                                        </button>
                                                    </div>
                                                    <p className="morecss">
                                                        <a href="">test <i className="fa-solid fa-arrow-right"></i></a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="carousel-item carousel-item-2">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-"></div>
                                            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-">
                                                <p><i className="fa-solid fa-quote-left"></i>Engineersmind exhibited a profound understanding of the intricate challenges within the healthcare sector. Engineersmind's software development and DevSecOps services have been outstanding...<i className="fa-solid fa-quote-right"></i></p>&nbsp;
                                                <p><b>Don Holder</b><br /><i>Technology Director (US Healthcare Services)</i></p>
                                                <div className="buttoncss">
                                                    <div className="control-btn-3">
                                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                            <i className="fa-solid fa-arrow-right"></i>
                                                            <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Next</span>
                                                        </button>
                                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                            <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                            <i className="fa-solid fa-arrow-left"></i>
                                                            <span className="visually-hidden">Previous</span>
                                                        </button>
                                                    </div>
                                                    <p className="morecss">
                                                        <a href="/Resources/Testimonials">More <i className="fa-solid fa-arrow-right"></i></a>
                                                    </p>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="carousel-item carousel-item-2">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-"></div>
                                            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-">
                                                <p><i className="fa-solid fa-quote-left"></i>From software development to business analysis, documentation, testing, and deployment, Engineersmind has consistently delivered outstanding results throughout our partnership. Their DevOps team has enabled us to smoothly introduce new software solutions, minimizing disruptions and maximizing our ROI...<i className="fa-solid fa-quote-right"></i></p>&nbsp;
                                                <p><b>Kaitlin Malin</b><br /><i>Chief Operating Officer (US Financial Services)</i></p>
                                                <div className="buttoncss">
                                                    <div className="control-btn-3">
                                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                            <i className="fa-solid fa-arrow-right"></i>
                                                            <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Next</span>
                                                        </button>
                                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                            <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                            <i className="fa-solid fa-arrow-left"></i>
                                                            <span className="visually-hidden">Previous</span>
                                                        </button>
                                                    </div>
                                                    <p className="morecss">
                                                        <a href="/Resources/Testimonials">More <i className="fa-solid fa-arrow-right"></i></a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div><br />
                                {/* <div className="buttoncss">
                                <p className="morecss">
                                    {/* <p><div className="success-text-1 morecss"> 
                                        <a href="/Resources/Testimonials">More <i className="fa-solid fa-arrow-right"></i></a>
                                    {/* </div> 
                                    </p>
                                    <span>box</span>
                                    <div className="control-btn-3">
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <i className="fa-solid fa-arrow-right"></i>
                                            <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                            <i className="fa-solid fa-arrow-left"></i>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid success extra-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-">
                            <h2><span>{textData.layer5.heading.text1}</span>{textData.layer5.heading.text2}</h2>
                            <div id="carouselExampleInterval" className="carousel slide">
                                <div className="carousel-inner">
                                {
                                    textData.layer5.editable_carousel.map((item, index) => {
                                        if (index === 0 ) {
                                            return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                            <div className="success-text-1">
                                                                <p>{item.text.text1}<br />{item.text.text2}<br />{item.text.text3}</p><br />
                                                                <a href={item.button.link}>{item.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                                                            </div>
                                                            <img src={item.img} className="d-block w-100" alt="banner-img" />
                                                        </div>
                                                    </div>
                                                </div>)
                                            } else {
                                                return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                   <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                            <div className="success-text-1">
                                                                <p>{item.text.text1}<br />{item.text.text2}<br />{item.text.text3}</p><br />
                                                                <a href={item.button.link}>{item.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                                                            </div>
                                                            <img src={item.img} className="d-block w-100" alt="banner-img" />
                                                        </div>
                                                    </div>
                                                </div>
                                        )}
                                    })
                                        
                                }
                                    {/* <div className="carousel-item carousel-item-1 active">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                <div className="success-text-1">
                                                    <p>Accelerated growth powered<br />by Data Engineering,<br /> AI and Automation</p><br />
                                                    <a href="/Services/AI&Data">Explore <i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                                <img src={layer3} className="d-block w-100" alt="banner-img" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item carousel-item-2">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                <div className="success-text-1">
                                                    <p>Driving operational excellence<br /> with DevOps and Cloud</p><br />
                                                    <a href="/Technologies/DevOps">Explore <i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                                <img src={layer2} className="d-block w-100" alt="banner-img" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item carousel-item-2">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                <div className="success-text-1">
                                                    <p>Build a scalable, agile and<br />reliable organization with<br />our Managed IT Services<br /></p><br />
                                                    <a href="/Services/ManagedITServices">Explore <i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                                <img src={layer1} className="d-block w-100" alt="banner-img" />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="control-btn-4">
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                        <i className="fa-solid fa-arrow-left"></i>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid services-home">
                <div className="container">
                    <h2><span>{textData.layer6.heading}</span></h2>
                    <p>{textData.layer6.body}</p>
                    {/* <div className="more-1">
                        <a href="/Services/ManagedITServices">Explore <i className="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-12 col-"></div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail1.link} id="pdng">{textData.layer6.details.detail1.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail2.link} id="pdng">{textData.layer6.details.detail2.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail3.link} id="pdng">{textData.layer6.details.detail3.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail4.link} id="pdng">{textData.layer6.details.detail4.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-"></div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-"></div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail5.link} id="pdng">{textData.layer6.details.detail5.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail6.link} id="pdng">{textData.layer6.details.detail6.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail7.link} id="pdng">{textData.layer6.details.detail7.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-"></div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12 col-">
                            <div className="value-text">
                                <h2><span>{textData.layer7.heading}</span></h2>
                                <h3>{textData.layer7.heading2.text1}<br />{textData.layer7.heading2.text2}</h3>&nbsp;
                                <p>{textData.layer7.heading3.text1}<br />{textData.layer7.heading3.text2}</p><br />
                                <a href={textData.layer7.button.link}>{textData.layer7.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-">
                            <div className="value-img">
                                <img src={textData.layer7.img} className="img-fluid" alt="intro-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-">
                            <div className="heading">
                            <h2><span>{textData.layer8.heading.text1}</span>{textData.layer8.heading.text2}<span>{textData.layer8.heading.text3}</span></h2>
                            </div>
                            <div className="more-2">
                            <a href={textData.layer8.button.url}>{textData.layer8.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-">
                            <div className="gif">
                            <img src={textData.layer8.button.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                            <h2>{textData.layer9.heading}</h2>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-">
                            <img src={textData.layer9.img} alt="logos" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Body;