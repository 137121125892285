import React from "react";
//import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Error from "./components/Error";
import Body from "./components/Body";
import Healthcare from "./components/healthcare";
import Salesforce from "./components/Salesforce";
import AboutUs from "./components/AboutUs";
import Resources from "./components/Resources";
import "./App.css";
import Contact from "./components/Contact";
import FinancialServices from "./components/FinancialServices";
import TechTalent from "./components/TechTalent";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import Manufacturing from "./components/Maufacturing";
import DevOps from "./components/Devops";
import Stack from "./components/Stack";
import Blogs from "./components/Blogs";
import News from "./components/News";
import Liferay from "./components/Liferay";
import ItServices from "./components/ItServices";
import SecurityServices from "./components/SecurityServices";
import FinancialCs2 from "./components/FinancialCs2";
import FinancialCs1 from "./components/FinancialCs1";
import HealthcareCs1 from "./components/HealthcareCs1";
import Careers from "./components/Careers";
import DigitalTransformation from "./components/DigitalTransformation";
import AiDataServices from "./components/AiDataService";
import Leadership from "./components/Leadership";
import Team from "./components/Team";
import Alliances from "./components/Alliances";
// import TechServices from "./components/TechServices";
import ManufacturingEC from "./components/MaufacturingEC";
import CharitableOrganization from "./components/CharitableOrganisation";
import Testimonials from "./components/Testimonials";
import AdminPage from "./components/Admin";
import Login from "./components/login";
import EditorPage from "./components/Editor";
import ContentManagementSystem from "./components/ContentManagementSystem"



const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Body />,  // dynamic ready
      },
      {
        path: "/About",
        element: <AboutUs />,  // dynamic ready
      },
      {
        path: "/Industries/FinancialServices",
        element: <FinancialServices />,  // dynamic ready
      },
      {
        path: "/Resources",
        element: <Resources />,  // dynamic Not Needed
      },
      {
        path: "/Contact",
        element: <Contact />,  // dynamic ready
      },
      {
        path: "/FinancialServices/ContentManagementSystem",
        element: <ContentManagementSystem />
      },
      {
        path: "/Industries/Healthcare",
        element: <Healthcare />,  // dynamic ready
      },
      {
        path: "/Manufacturing/EcommercePortal",
        element: <ManufacturingEC />,  // dynamic ready
      },
      {
        path: "/Healthcare/CharitableOrganization",
        element: <CharitableOrganization />, // dynamic ready
      },
      {
        path: "/Technologies/Salesforce",
        element: <Salesforce />,  // dynamic Not Needed
      },
      {
        path: "/Services/TechnologyTalent",
        element: <TechTalent />,  // dynamic ready
      },
      {
        path: "/PrivacyPolicy",
        element: <PrivacyPolicy />,  // dynamic Not Needed
      },
      {
        path: "/Termsofuse",
        element: <TermsOfUse />,    // dynamic Not Needed
      },
      {
        path: "/Industries/Manufacturing",
        element: <Manufacturing />,  // dynamic ready
      },
      {
        path: "/Resources/News",
        element: <News />,    // dynamic ready
      },
      {
        path: "/Resources/Blogs",
        element: <Blogs />,   // dynamic ready
      },
      {
        path: "/Resources/SuccessStories",
        element: <Resources />,  // dynamic Not Needed
      },
      {
        path: "/Technologies/Liferay",
        element: <Liferay />,  // dynamic ready
      },
      {
        path: "/Technologies/FullStackDevelopment",
        element: <Stack />,  // dynamic ready
      },
      {
        path: "/Technologies/DevOps",
        element: <DevOps />,  // dynamic ready
      },
      {
        path: "/Services/ManagedITServices",
        element: <ItServices />,  // dynamic ready
      },
      {
        path: "/Services/SecurityCertificationsServices",
        element: <SecurityServices />,   // dynamic ready
      },
      {
        path: "/About/EMLeadership",
        element: <Leadership />,   // dynamic ready
      },
      {
        path: "/About/Team",
        element: <Team />,   // dynamic ready
      },
      {
        path: "/About/Alliances",
        element: <Alliances />,  // dynamic Not Needed
      },
      {
        path: "/About/Careers",
        element: <Careers />,   // dynamic ready
      },
      {
        path: "/Healthcare/IntegratedUserExperiences",
        element: <HealthcareCs1 />,  // dynamic Not Needed
      },
      {
        path: "/FinancialServices/FINRACompliance",
        element: <FinancialCs1 />,     // dynamic ready
      },
      {
        path: "/FinancialServices/AutomatingTradeSystems",
        element: <FinancialCs2 />,    // dynamic Not Needed
      },
      {
        path: "/Services/DigitalTransformation", 
        element: <DigitalTransformation />,   // dynamic Not Needed
      },
      {
        path: "/Services/AI&Data",
        element: <AiDataServices />,    // dynamic ready
      },
      {
        path: "/Resources/Testimonials",
        element: <Testimonials />,  // dynamic ready
      },
      {
        path: "/AdminPage",
        element: <AdminPage />,  // dynamic Not Needed
      },
      {
        path: "/EditorPage",
        element: <EditorPage />,  // dynamic Not Needed
      },
      {
        path: "/login",
        element: <Login />,  // dynamic Not Needed
      }
    ],
  },
]);

//const root = ReactDOM.createRoot(document.getElementById("root"));
//root.render(<RouterProvider router={appRouter} />);

const root = createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);
