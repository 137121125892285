
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { Hourglass } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import page_json from './config/config.json'

function Editor() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);
	const navigate = useNavigate();
	async function loginUser(credentials) {
		return fetch(page_json.backend_apis.login, {
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json'
		  },
		  body: JSON.stringify(credentials)
		})
		.then((data) => data.json())
		.catch(error => {
			alert("LOGIN FAILED!");
			window.location.reload();
			throw(error);
		});
	}
	const [username, setUserName] = useState();
	const [password, setPassword] = useState();
	const handleSubmit = async e => {
		e.preventDefault();
		document.getElementsByClassName("loginBox")[0].style.display = "none";
		document.getElementsByClassName("loaderlogin")[0].style.display = "flex";
		const token = await loginUser({
		  "id": username,
		  "pass": password
		});
		sessionStorage.setItem("key", token.key);
		sessionStorage.setItem("role", token.role);
		document.getElementsByClassName("loginBox")[0].style.display = "flex";
		document.getElementsByClassName("loaderlogin")[0].style.display = "none";
		alert("LOGIN SUCCESSFUL!");
		if (token.role === "editor") {
			navigate("/EditorPage");
		} else if (token.role === "admin"){
			navigate("/AdminPage");
		}
	}
	return (
		<div data-aos="fade-up">
			<div className="container-fluid m-0 p-0">
				<div className="login-wrapper loginBox">
					<h1>Please Log In</h1>
					<form onSubmit={handleSubmit}>
					<label>
						<p>Username</p>
						<input type="text" onChange={e => setUserName(e.target.value)} />
					</label>
					<label>
						<p>Password</p>
						<input type="password" onChange={e => setPassword(e.target.value)} />
					</label>
					<div>
						<button className="submitLogin" type="submit">Submit</button>
					</div>
					</form>
				</div>
				<div className="loaderlogin">
					<Hourglass
					height="80"
					width="80"
					ariaLabel="hourglass-loading"
					wrapperStyle={{}}
					wrapperClass=""
					colors={['#306cce', '#72a1ed']}
					/>
				</div>
			</div>

		</div>
	)
}

export default Editor;